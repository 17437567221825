<template>
<div>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="metrics" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="true"></filter-interface>
          <sort-interface class="mr-4" namespace="metrics" :rows="rowsFiltered" v-model="rowsSorted" :optionsProperty="sortProperties"></sort-interface>
          <search-interface namespace="metrics" :rows="rowsSorted" v-model="rowsSearched"></search-interface>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
          <line-item :item="item" :trim="400"></line-item>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import LineItem from '@/views/metrics/MetricSimple.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import SortInterface from '@/components/SortInterfaceV1.vue'

export default {
  name: 'TaxHome',
  components: {
    FilterInterface,
    LineItem,
    SearchInterface,
    SortInterface
  },
  computed: {
    rows: function () {
      return this.$store.state.metrics
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'name', text: 'Name' }
      ],
      staticDataProperties: [
        { name: 'Reporting Frameworks', property: 'reportingframeworks', graph: 'reportingframeworks' }
      ]
    }
  },
  created: function () {
    document.title = "ESG Metrics"
  }
}
</script>

<style>
</style>
